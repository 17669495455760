import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Container, Row, Column } from '@common/Grid';
import { Text } from '@common/components/Text';
import Widget from './Widget';
import WidgetDefault from './WidgetDefault';

const Section = styled('div')`
  background-color: ${props => props.backgroundColor};
`;

const ColumnWrapIframe = styled(Column)`
  z-index: 0;
`;

const Overflow = styled('div')`
  overflow: auto;
`;

const WidgetBlock = ({ block, ...props }) => {
  const widget = block?.widget ?? '';
  const backgroundColor = block?.backgroundColor ?? '#ffffff';

  return (
    <Section backgroundColor={backgroundColor}>
      <Container py={[6, 8, 8, 10]} px={[3, 4, 2]}>
        <Row justifyContent="center">
          {widget.length > 0 ? (
            <ColumnWrapIframe width={[1, 5 / 6, 2 / 3]}>
              <Overflow>
                <Widget {...props} widget={widget} />
              </Overflow>
            </ColumnWrapIframe>
          ) : (
            <Column width={1}>
              <WidgetDefault
                href="https://nethouse.ru/about/instructions/kak_dobavit_yandeks_poisk_dlya_sajta"
                title={
                  <FormattedMessage
                    id="block.default.name"
                    defaultMessage="Яндекс.Поиск для сайта"
                  />
                }
              />
            </Column>
          )}
        </Row>
      </Container>
    </Section>
  );
};

WidgetBlock.propTypes = {
  block: PropTypes.shape({
    widget: PropTypes.string,
    backgroundColor: PropTypes.string
  })
};

export default WidgetBlock;
